/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import Form from '../components/form';
import InternalLink from '../components/internal-link';

const NotFoundPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={location}
        >
            <SEO
                title="Page Not Found"
            />
            <Section narrow>
                <h1>Page not found</h1>
                <p>Perhaps you were looking for one of these pages:</p>
                <ul>
                    <li>
                        <InternalLink to="/about/">
                            About me
                        </InternalLink>
                    </li>
                    <li>
                        <InternalLink to="/articles/">
                            My Articles
                        </InternalLink>
                    </li>
                    <li>
                        <InternalLink to="/wordpress-consultant/">
                            WordPress Consultant
                        </InternalLink>
                    </li>
                </ul>
                <h2 id="hire-me">I&apos;m available for hire</h2>
                <p>Fill out the form below to start a conversation with me:</p>
                <Form
                    id="contact-form"
                    contact
                    buttonAsideText="I'll reply as soon as I can!"
                    messagePlaceholder="Hi Tom, I have a project to talk with you about."
                    pathname={location && location.pathname}
                />
            </Section>
        </Layout>
    );
};

NotFoundPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

NotFoundPage.defaultProps = {
    location: {},
};

export default NotFoundPage;
